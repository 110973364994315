

























import {Component, Prop, Vue, Watch} from 'vue-property-decorator';

@Component({
    components: {}
})
export default class MoodSelect extends Vue {
    @Prop({default: 5})
    public readonly ratingNumber!: number;
    private moodValue!: number;
    private borderStyle: string = this.getBorderStyle();
    @Prop({})
    public  color!: string ;
    @Prop({default: 3})
    public readonly value!: number;
    @Watch('color')
    protected onColorChanged() {

        this.borderStyle= this.getBorderStyle();
    }
    @Watch('value')
    protected onValueChanged(newValue: any, oldValue: any) {
        this.moodValue = newValue;
        this.$emit("changed", this.moodValue);
    }

    @Watch("moodValue")
    moodValueChange() {
        this.$emit("input", this.moodValue);
    }

    private getBorderStyle() : string {
        if(this.color) {
            return "border: 2px solid " + this.color;
        }
        return "border: 2px solid transparent";
    }
    constructor() {
        super();
        this.moodValue = this.value;
    }

}
